import React from 'react';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from "aws-amplify-react";
import './App.css';

Amplify.configure(awsconfig);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="Icon_blank.png" className="App-logo" alt="logo" />
        <p>Welcome to POP Shooter.</p>
      </header>
    </div>
  );
}

export default withAuthenticator(App, {hiddenDefaults:["username"]});
