// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:c67f4e95-e7b0-48c2-a970-b236a38b3088",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_1xqao1vJA",
    "aws_user_pools_web_client_id": "6s4p5hb5t0p55064rjbhchbm2i",
    "oauth": {
        "domain": "popshooter9a1373b8-9a1373b8-web.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost/login/redirect/",
        "redirectSignOut": "http://localhost/logout/redirect/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://ytfzqbjpzrfqzmeryo4dt62u3q.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "popshooter-dev-website-web",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://dy4koe5nu21xt.cloudfront.net"
};


export default awsmobile;
